export default {
    namespaced: true,
    state: {
        mainMenuList: [
            {'className': 'btn-15', "path": '/expand/chat', 'name': 'ChatGPT', 'role': 'ordinary'},
            {'className': 'btn-3', "path": '/expand/externalApp', 'name': '创意应用', 'role': 'ordinary'},
            {'className': 'btn-2', "path": '/mailbox/list', 'name': "时光邮差", 'role': 'ordinary'},
            {
                'className': 'btn-11',
                "path": '/expand/christmasTree/morningReport',
                'name': "时光日记",
                'role': 'ordinary'
            },
            {'className': 'btn-14', "path": '/expand/christmasTree/souvenir', 'name': "时光相册", 'role': 'ordinary'},
            {'className': 'btn-13', "path": '/expand/fileUpload', 'name': "文件快传", 'role': 'ordinary'},
            {'className': 'btn-6', "path": '/player', 'name': "视频播放器", 'role': 'ordinary'},
            {'className': 'btn-4', "path": '/tvRoom', 'name': '一起看', 'role': 'ordinary'},
            {'className': 'btn-4', "path": '/expand/nas', 'name': 'Nas', 'role': 'admin'},
            {'className': 'btn-1', "path": '/treeHole/group', 'name': "树洞先生", 'role': 'ordinary'},
            {'className': 'btn-5', "path": '/tv', 'name': '电视频道', 'role': 'ordinary'},
            {'className': 'btn-7', "path": '/expand/mediaLibrary', 'name': '图库', 'role': 'ordinary'},
            {'className': 'btn-1', "path": '/expand/videoPlay', 'name': '播放器', 'role': 'ordinary'},
            {'className': 'btn-15', "path": '/expand/filev/index', 'name': '离线下载', 'role': 'admin'},
            {'className': 'btn-15', "path": '/expand/down/down', 'name': 'DOWN下载', 'role': 'admin'},
            // {'className': 'btn-10', "path": 'https://www.vkna.cn/', 'name': 'Fc小游戏', 'role': 'ordinary'},
            {'className': 'btn-8', "path": '/pv', 'name': 'Pv', 'role': 'pv'},
            {'className': 'btn-8', "path": '/expand/down/index', 'name': 'Nas影视', 'role': 'down-pv'},
            {'className': 'btn-15', "path": '/expand/novel/index', 'name': '小说', 'role': 'pv'},
            {'className': 'btn-1', "path": '/expand/weChatTemplate', 'name': '公众号消息', 'role': 'admin'}
        ],
        pvList: [
            {value: 1, name: "大橙"},
            {value: 2, name: "老鸭", host: "https://api.apilyzy.com/api.php/provide/vod/at/json"},
            {value: 3, name: "黄瓜", host: "https://www.avre06.com/api.php/provide/vod/at/json"},
            {value: 4, name: "鲨鱼", host: "http://shayuzy5.com/api.php/provide/vod/at/json"},
            {value: 5, name: "乐播", host: "http://lebozy5.com/api.php/provide/vod/at/json"},
            {value: 6, name: "丝袜", host: "https://www.siwazyw.tv/api.php/provide/vod/at/json"},
            {value: 7, name: "玉兔", host: "https://yutuzy.com/api.php/provide/vod/at/json"},
            {value: 8, name: "百万", host: "https://baiwanzy5.cc/api.php/provide/vod/at/json"},
            {value: 9, name: "GAY", host: "https://gayziyuan.com/api.php/provide/vod/at/json"},
            {value: 10, name: "鲨鱼", host: "https://shayuapi.com/api.php/provide/vod/at/json"},
        ]
    },
    getters: {
        getMainMenuList: state => state.mainMenuList,
        getPvList: state => state.pvList
    },
    actions: {
        //
    },
    mutations: {
        pushMainMenuList: (state, data) => state.mainMenuList.push(data)
    }
}
