import axios from "axios";
import StorageHandler from "../utils/StorageHandler";
import SystemConfig from "../config/SystemConfig";
import {Message} from "element-ui";
import cryptoUtil from "@/utils/cryptoUtil";

//默认请求响应超时时间
axios.defaults.timeout = 1000 * 3000;
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
//HTTPrequest拦截
axios.interceptors.request.use(
    config => {
        // 设置请求格式
        //config.headers["Content-Type"] = "application/json";

        // 设置token
        config.headers["Authorization"] = StorageHandler.getSessionStorage('token');
        // config.headers["Authorization"] = '1'

        // 是否需要签名
        // if (SystemConfig.sign) {
        //   let signHanler = new SignHanler();
        //   signHanler.handler(config);
        // }
        return config;
    },
    error => {
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);
//HTTPresponse拦截
axios.interceptors.response.use(
    data => {
        let cryptoU = new cryptoUtil();
        const isEncrypt = data.headers['charon-value-encrypt']
        if (isEncrypt != null && isEncrypt === 'true') {
            try {
                if (data.data.msg) {
                    data.data.msg =  JSON.parse(cryptoU.decryptFun(data.data.msg));
                    data.data.data =  JSON.parse(cryptoU.decryptFun(data.data.data));
                } else {
                    return JSON.parse(cryptoU.decryptFun(data.data));
                }
            }catch (e){
                return data.data
            }
        }
        return data.data;
    },
    error => {
        console.log(error);
        if (error.response != null) {
            if (error.response.status === 401) {
                Message({showClose: true, message: error.response.data.message, type: 'error'});
                StorageHandler.clearStorage()
                window.location.href = '/login'
                return Promise.reject(error.response.data)
            } else {
                if (error.response.data.message === 'T1000401:会话已过期') {
                    window.location.href = '/login'
                    StorageHandler.removeSessionStorage("userInfo")
                }
                Message({
                    showClose: true,
                    message: error.response.data.message,
                    type: "error"
                });
                return Promise.reject(error.response.data.message);
            }
        }
    }
);

export default axios;
