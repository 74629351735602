/**
 * spider工具类
 */

/**
 * tv资源类型判断
 * @param orgPlayItem 搜索来源
 * @param tvItem 资源实体
 * @returns {number} spiderId
 */
export function tvTypeJudge(orgPlayItem, tvItem) {
    let spiderId;
    let elseId;
    if (orgPlayItem != null && orgPlayItem.resTypeJump.length > 0) {
        orgPlayItem.resTypeJump.forEach(item => {
            if (item.fieldName == 'else') {
                elseId = item.fieldSpiderId;
            } else if (tvItem[item.fieldName].indexOf(item.fieldValue) > -1) {
                spiderId = item.fieldSpiderId;
                return;
            }
        })
    }
    return spiderId == null ? elseId : spiderId;
}

/**
 * 用户角色菜单验证
 * @param role 用户角色
 * @param menu 菜单角色
 */
export function roleVerifyMenu(role, menu) {
    if (menu === 'ordinary') {
        return true
    } else if (role === 'admin') {
        return true;
    }
    return role === menu
}

/**
 * 跳转Tv下载-down服务下载
 * @param id
 * @param titleName
 * @param tvImg
 * @param spiderId
 * @param playType
 */
export function jumpToVideoDownload({link, img, title}) {
    if (this.$store.state.user.userInfo.luAdmin) {
        this.$router.push({
            name: 'down_service_down',
            params: {
                link, img, title
            }
        });
    }
}